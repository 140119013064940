import React from 'react'
import { graphql, useStaticQuery  } from 'gatsby'
import Layout from '../components/Layout'
import "../styles/mystyles.scss"



export default function Index() {
  const data = useStaticQuery(graphql`
  query getNews {
    allNewsJson(sort: {order: DESC, fields: cDato}) {
      nodes {
        cDato
        header
        content
        id
      }
    }
  }
    `)




const news = data.allNewsJson.nodes
  return (
            <Layout >
              <div className="containerPersonal" style={{gridTemplateColumns: '2fr 1fr'}}>
                <div className="containerPersonal" style={{gridTemplateColumns: '2fr'}}>
                  {news.map(content => (
                    <div className="card" key={content.id}>
                      <p className="card-header-title">
                        {content.header}
                      </p>
                      <div className="card-content">
                        <div className="content" dangerouslySetInnerHTML={{__html:content.content}}>
                          
                        
                        </div>
                        <br></br>
                        <time dateTime={content.cDato}>{content.cDato}</time>
                      </div>
                      

                          
                     
                    </div>
                    ))}
                </div>
                <div className="card" key="information" style={{gridTemplateColumns: '1fr'}} >
                  <p className="card-header-title">
                    Overblik
                  </p>
                  <div className="card-content">
                    <div className="content">

                    </div>
                  </div>
                </div>
              </div>
            </Layout>
  )
}