import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default function Footer() {
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          copyright
          copyrightDate
        }
      }
    }
  `)
  const { copyright, copyrightDate } = data.site.siteMetadata
    return (
        <footer className="footer">
          <div className="content has-text-centered">
            <p>This website is copyright {copyrightDate} <a href="https://topdrive.dk" target="_blank" rel="noreferrer">{ copyright }</a></p>
          </div>
        </footer>
    )
}
