import React from 'react'
import Link from 'gatsby-link'


import logo from '../../static/images/logo/logo_animated.gif'


export default function Navbar() {
  

  

  

    return (
      <nav className="navbar " role="navigation" >
            <div className="navbar-brand" style={{margin:'20px 0px 20px 0px'}}>
              <a className="" href="https://staun-vandvaerk.dk">
                <img src={logo} alt="Staun Vandværk" />
              </a>
            </div>
      
            <div className="navbar-end" >
                <Link className="navbar-item" activeClassName="is-active" style={{margin:'30px 0px 30px 0px'}} to="/">Home</Link>
                <Link className="navbar-item" activeClassName="is-active" style={{margin:'30px 0px 30px 0px'}} to="/waterquality">Vand kvalitet</Link>
                <Link className="navbar-item" activeClassName="is-active" style={{margin:'30px 0px 30px 0px'}} to="/price">Priser</Link>
                <Link className="navbar-item" activeClassName="is-active" style={{margin:'30px 0px 30px 0px'}} to="/about">Om Vandværket</Link>
                <Link className="navbar-item" activeClassName="is-active" style={{margin:'30px 0px 30px 0px'}} to="/contact">Kontakt</Link>
            </div>
        </nav>
    )
}
