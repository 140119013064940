import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
//import '../styles/global.css'

export default function Layout({ children }) {
    return (<div className="container">
                <div className=" columns is-flex-direction-column is-fullheight-100vh">
                    <Navbar className="column"/>
                    
                    <div className="content column" style={{flex:true, height:'50%'}}>
                        { children }
                    </div>

            
                    <Footer className="column" />
                </div>
            </div>
    )
}
